<script lang="ts" setup>
import type { SearchProduct } from '~/types/api/search'

interface Props {
  product: SearchProduct
  salePreview?: boolean
}

const { product, salePreview } = withDefaults(defineProps<Props>(), {
  salePreview: false,
})
const { getDiscount, getUrl } = useProduct()

const discount = getDiscount(product)
const url = getUrl(product)

const componentData = computed(() => {
  if (product.isSold) {
    return {
      is: 'div',
    }
  }

  return {
    is: resolveComponent('NuxtLinkLocale'),
    to: `/product/${url}`,
  }
})
</script>

<template>
  <Component :is="componentData.is" v-bind="componentData">
    <UCard
      class="h-full"
      :ui="{
        base: 'relative',
        header: {
          base: 'absolute top-4 right-4 flex gap-2 text-xs font-medium z-10',
          padding: '',
        },
        body: { base: 'h-full' },
      }"
    >
      <template #header>
        <p
          v-if="product.isOnSale"
          class="rounded-full bg-primary-600 px-2 py-1 capitalize text-white"
        >
          {{ $t('global.flash-sale') }}
        </p>
        <p
          v-if="discount > 0"
          class="rounded-full bg-orange-600 px-2 py-1 text-white"
        >
          -{{ discount }}%
        </p>
      </template>

      <div class="flex h-full flex-col text-sm">
        <div class="relative mb-6">
          <div
            v-if="product.isSold"
            class="absolute inset-0 flex items-center justify-center bg-white/60"
          >
            <span
              class="rounded bg-red-200 px-3 py-1.5 capitalize text-red-700"
            >
              {{ $t('global.sold-out') }}
            </span>
          </div>
          <NuxtPicture
            :src="product.image"
            :alt="product.name"
            :img-attrs="{ class: 'mx-auto size-40 min-w-40 object-contain' }"
            sizes="160px"
            quality="60"
            :modifiers="{ trim: '10' }"
            loading="lazy"
          />
        </div>
        <p class="text-lg font-bold">{{ product.name }}</p>

        <p class="mt-auto">
          {{ $t('global.condition') }}: {{ product.condition }}
        </p>
        <p class="font-semibold">
          {{ salePreview ? product.price : $n(product.price, 'currency') }}
          <small
            v-if="discount > 0 || salePreview"
            class="text-xs font-medium text-gray-500 line-through"
          >
            {{ $n(product.parentPrice, 'currency') }}
          </small>
        </p>
        <div class="mt-1 flex flex-wrap gap-2">
          <UITag v-for="tag in product.tags" :key="tag.id">
            {{ tag.value }}
          </UITag>
        </div>
      </div>
    </UCard>
  </Component>
</template>
